import * as React from "react"
import { render } from "react-dom"
import { PortableText } from "@portabletext/react"
import Slider from "../Slider"
import { getImageUrl } from "../../utils/getImageUrl"
import LazyLoad from "react-lazy-load"
import parse from "html-react-parser"
import { useEffect } from "react"
import { ChevronUpIcon } from "@heroicons/react/24/outline"

const HEADERS = ["H1", "H2", "H3", "H4", "H5", "H6"]

const Content = ({ content, contentData }) => {
  const startHeaderTag = "&lt;START_ACCORDION_HEADER&gt;"
  const endHeaderTag = "&lt;END_ACCORDION_HEADER&gt;"
  const startBodyTag = "&lt;START_ACCORDION_BODY&gt;"
  const endBodyTag = "&lt;END_ACCORDION_BODY&gt;"
  let modifiedContent
  if (contentData) {
    modifiedContent = contentData
      .replaceAll(startHeaderTag, `<span className="accordionSpan" >`)
      .replaceAll(endHeaderTag, "</span>")
      .replaceAll(
        startBodyTag,
        `<div className="accordionBody closedAccordionBody" >`
      )
      .replaceAll(endBodyTag, "</div>")
  }
  const findClosestHeader = node => {
    if (node) {
      if (HEADERS.includes(node.tagName)) {
        return node
      } else {
        return findClosestHeader(node.parentNode)
      }
    }
  }

  const handleHeaderClick = event => {
    const headerBlock = findClosestHeader(event.target)
    if (headerBlock) {
      let nextElement = headerBlock.nextElementSibling

      while (nextElement) {
        if (
          !HEADERS.includes(nextElement.tagName) &&
          nextElement.classList.contains("accordionBody")
        ) {
          const currentValue = window.getComputedStyle(nextElement).maxHeight
          nextElement.style.maxHeight = currentValue === "0px" ? "100%" : "0px"
          nextElement.classList.contains("closedAccordionBody")
            ? nextElement.classList.remove("closedAccordionBody")
            : nextElement.classList.add("closedAccordionBody")

          headerBlock.classList.contains("closedAccordionHeader")
            ? headerBlock.classList.remove("closedAccordionHeader")
            : headerBlock.classList.add("closedAccordionHeader")

          break
        }
        nextElement = nextElement.nextElementSibling
      }
    }
  }
  useEffect(() => {
    const allAccordionHeaders = document.querySelectorAll(".accordionSpan")
    allAccordionHeaders.forEach(accordionHeader => {
      const header = findClosestHeader(accordionHeader)
      header.classList.add("accordionHeader")
      const divWithIcon = document.createElement("div")
      divWithIcon.classList.add("chevronUpIcon")
      render(<ChevronUpIcon height="100%" />, divWithIcon)
      header.appendChild(divWithIcon)
      header.addEventListener("click", handleHeaderClick)
    })
  }, [])

  return (
    <div className="elva content px-4 lg:p-2.5 pb-12 lg:pb-16">
      {contentData && parse(modifiedContent)}
      {content &&
        content.map(item => {
          return (
            <div key={item._key}>
              <h3 className="mb-2 mt-5">
                <strong>{item.heading}</strong>
              </h3>
              <div>
                {item.image && (
                  <LazyLoad>
                    <img
                      className={`w-full h-full ${
                        item.layout === "imageleft"
                          ? "md:float-left md:mx-4 md:mb-4 md:w-1/2"
                          : `${
                              item.layout === "imageright"
                                ? "md:float-right md:mx-4 md:mb-4 md:w-1/2"
                                : ""
                            } `
                      }`}
                      style={{ maxWidth: `${item.imageSize}px` }}
                      src={getImageUrl(item.image?.asset?._ref)}
                      alt={item.heading}
                    />
                  </LazyLoad>
                )}
                <PortableText value={item.content} />

                {item._type === "logos" && (
                  <div className="flex flex-wrap justify-center gap-4 items-center">
                    {item.logos.map(image => (
                      <LazyLoad>
                        <img
                          className={`w-full h-full object-cover`}
                          style={{ maxWidth: `150px`, maxHeight: `150px` }}
                          src={getImageUrl(image?.asset?._ref)}
                        />
                      </LazyLoad>
                    ))}
                  </div>
                )}

                {item._type === "imageGrid" && (
                  <Slider data={item.gridImages} />
                )}
              </div>
              <div className="clear" />
            </div>
          )
        })}
    </div>
  )
}

export default Content
